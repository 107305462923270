<template>
    <!-- Inicio Apartado si parámetro "gestion_baremos" está activado -->
    <seccion-datos :titulo="baremosdelacompania" :botonGuardar="false" tipo="secondary">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th>{{ $t('general.nombre') }}</th>
                    <th>{{ $t('general.fechainicio') }}</th>
                    <th>{{ $t('general.fechafin') }}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>AXA 2021</td>
                    <td>01/01/2021</td>
                    <td>15/03/2022</td>
                </tr>
                <tr>
                    <td>OPERARIOS 2021</td>
                    <td>01/01/2021</td>
                    <td>31/12/2021</td>
                </tr>
            </tbody>
        </table>
        <template v-slot:footer>
            <p>{{ $t('general.baremos2') }}<router-link :to="{name: 'Baremos'}">{{ $t('general.baremos') }}</router-link></p>
        </template>
    </seccion-datos>
    <!-- Fin Apartado si parámetro "gestion_baremos" está activado -->
</template>
<script>
//import CompaniaBaremosTrabajos from './CompaniaBaremosTrabajos.vue'
export default {
  /*components: {
    'compania-baremos-trabajos': CompaniaBaremosTrabajos
  }*/
    mounted() {


        this.baremosdelacompania = this.$t('general.baremos');


     }
}
</script>
